import React, { useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { BurgerTypes, creator } from "./burgerStore";
import { useParams } from "react-router";
import { Burger } from "../../burgers/burger";
const BurgerDetails = () => {
  const { burgerName } = useParams();
  const [burger, setBurger] = React.useState<Burger>(
    creator(BurgerTypes.chicken)
  );

  useEffect(() => {
    setBurger(creator(burgerName as BurgerTypes));
  }, [burgerName]);
  return (
    <Row className="w-full h-full" justify={"center"} align={"middle"}>
      <Col className="space-y-8 py-8" span={20}>
        <Row gutter={16} align={"middle"}>
          <Col span={20}>
            <Row className="w-full">
              <Col span={24} className="space-y-3">
                <Row>
                  <Col>
                    <b>Name</b>
                    <p>{burger?.getName()}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>Description</b>
                    <p>{burger?.getDescription()}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>Ingredients</b>
                    <p>{burger?.getIngredients().join(", ")}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Row justify={"start"}>
              <Col>
                <Row>
                  <Col>
                    {burger?.getImageArray().map((image) => (
                      <Row>
                        <Col>
                          <img
                            src={image}
                            alt="alt"
                            width={100}
                            height={"auto"}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BurgerDetails;
