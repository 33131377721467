import React from "react";
import { Row, Col, Button } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { BurgerBuilder } from "../../../burgers/buildYourOwnBurger";

import { Cheese, Patty, Sauce, Veggy } from "../../../burgers/CustomizeBurger";

const CustomBurger = () => {
  const [burger, setBurger] = React.useState<BurgerBuilder>(
    new BurgerBuilder().basicBurger()
  );

  const onAdd = (ingredient: Patty | Cheese | Veggy | Sauce) => {
    const currentBurger = cloneDeep(burger);
    currentBurger.addIngredient(ingredient);
    setBurger(currentBurger);
  };

  return (
    <Row justify={"center"} align={"middle"} className="w-full h-full">
      <Col className="space-y-8">
        <Row justify={"center"}>
          <Col>
            {burger.burger.map((ingredient) => (
              <Row>
                <Col>
                  <img src={ingredient} width="100" alt="ingredient" />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="space-y-4">
            <Row>
              <Col>
                <Button onClick={() => onAdd(Patty.beef)}>Beef</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => onAdd(Patty.chicken)}>Chicken</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => onAdd(Patty.veg)}>Veg</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => onAdd(Patty.pork)}>Pork</Button>
              </Col>
            </Row>
          </Col>
          <Col className="space-y-4">
            <Row>
              <Col>
                <Button onClick={() => onAdd(Veggy.tomato)}>Tomato</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => onAdd(Veggy.onion)}>Onion</Button>
              </Col>
            </Row>
          </Col>
          <Col className="space-y-4">
            <Row>
              <Col>
                <Button onClick={() => onAdd(Sauce.mayo)}>Mayo</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => onAdd(Sauce.mustard)}>Mustard</Button>
              </Col>
            </Row>
          </Col>
          <Col className="space-y-4">
            <Row>
              <Col>
                <Button onClick={() => onAdd(Cheese.black)}>Black</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => onAdd(Cheese.cheddar)}>Cheddar</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <Button
              onClick={() =>
                alert(
                  `Your burger is being prepared with following ingredients \n ${burger.ingredients.join(
                    "\n"
                  )}`
                )
              }
              className="bg-red-500 text-white font-bold hover:!bg-red-500 hover:!text-white"
            >
              Order
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CustomBurger;
