import { Burger } from "./burger";
import RegularTop from "../svgs/buns/dual-top.png";
import RegularBottom from "../svgs/buns/dual-bottom.png";
import Cheese from "../svgs/ingreditents/cheddar-cheese.png";
import Chicken from "../svgs/patties/chicken.png";
import Tomato from "../svgs/ingreditents/tomato.png";
import Mustard from "../svgs/ingreditents/mustard-sauce.png";
import Lettuce from "../svgs/ingreditents/lettuce.png";
import Onion from "../svgs/ingreditents/onion.png";
import { BurgerTypes } from "../withPatterns/Factory/burgerStore";
export class ChickenBurger implements Burger {
  getBurgerType() {
    return BurgerTypes.chicken;
  }
  getName(): string {
    return "Chicken Burger";
  }
  getDescription(): string {
    return "A chicken burger is a savory and delicious sandwich made with a ground or grilled chicken patty, typically seasoned with various spices and herbs for flavor. The chicken patty is placed inside a bun and may be accompanied by various toppings such as lettuce, tomatoes, onions, and condiments like mayonnaise or barbecue sauce. ";
  }
  getImageArray(): string[] {
    return [
      RegularTop,
      Tomato,
      Lettuce,
      Onion,
      Mustard,
      Cheese,
      Chicken,
      RegularBottom,
    ];
  }
  getIngredients(): string[] {
    return [
      "Hamburger Bun",
      "Tomato",
      "Lettuce",
      "Onion",
      "Mustard Sauce",
      "Cheese",
      "Chicken Patty",
    ];
  }
}
