import RegularTop from "../svgs/buns/dual-top.png";
import RegularBottom from "../svgs/buns/dual-bottom.png";
import Cheese from "../svgs/ingreditents/cheddar-cheese.png";
import Beef from "../svgs/patties/beef.png";
import Tomato from "../svgs/ingreditents/tomato.png";
import Mayo from "../svgs/ingreditents/mayonaisse.png";
import { Burger } from "./burger";
import { BurgerTypes } from "../withPatterns/Factory/burgerStore";
export class CheeseBurger implements Burger {
  getBurgerType() {
    return BurgerTypes.cheese;
  }
  getName() {
    return "Cheese Burger";
  }
  getDescription() {
    return "A cheeseburger is a classic and popular type of hamburger that includes the addition of cheese, typically melted over the patty.";
  }
  getIngredients() {
    return [
      "Hamburger bun",
      "Cheese",
      "Lettuce",
      "Tomato",
      "Mayonaisse",
      "Beef patty",
    ];
  }
  getImageArray() {
    return [RegularTop, Tomato, Mayo, Cheese, Beef, RegularBottom];
  }
}
