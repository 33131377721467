import { Burger } from "./burger";
import RegularTop from "../svgs/buns/dual-top.png";
import RegularBottom from "../svgs/buns/dual-bottom.png";
import Cheese from "../svgs/ingreditents/cheddar-cheese.png";
import Potato from "../svgs/patties/potato.png";
import Tomato from "../svgs/ingreditents/tomato.png";
import Mustard from "../svgs/ingreditents/mustard-sauce.png";
import Lettuce from "../svgs/ingreditents/lettuce.png";
import Onion from "../svgs/ingreditents/onion.png";
import { BurgerTypes } from "../withPatterns/Factory/burgerStore";
export class VegBurger implements Burger {
  getBurgerType() {
    return BurgerTypes.veg;
  }
  getName(): string {
    return "Veg Burger";
  }
  getDescription(): string {
    return " A Veg Burger is a plant-based burger option that typically consists of a veggie patty made from ingredients like vegetables, legumes, or grains. It is often served in a bun and can be customized with various toppings such as lettuce, tomatoes, onions, and condiments like ketchup or vegan mayo";
  }
  getImageArray(): string[] {
    return [
      RegularTop,
      Tomato,
      Lettuce,
      Onion,
      Mustard,
      Cheese,
      Potato,
      RegularBottom,
    ];
  }
  getIngredients(): string[] {
    return [
      "Hamburger Bun",
      "Tomato",
      "Lettuce",
      "Onion",
      "Mustard Sauce",
      "Cheese",
      "Mashed Potato Patty",
    ];
  }
}
