import RegularTop from "../svgs/buns/dual-top.png";
import RegularBottom from "../svgs/buns/dual-bottom.png";
import Cheddar from "../svgs/ingreditents/cheddar-cheese.png";
import Black from "../svgs/ingreditents/black-cheese.png";
import Beef from "../svgs/patties/beef.png";
import Chicken from "../svgs/patties/chicken.png";
import Veg from "../svgs/patties/potato.png";
import Pork from "../svgs/patties/pork.png";

import Tomato from "../svgs/ingreditents/tomato.png";
import Onion from "../svgs/ingreditents/onion.png";
import Mayo from "../svgs/ingreditents/mayonaisse.png";
import TomatoKetcup from "../svgs/ingreditents/tomato-ketchup.png";
import Mustard from "../svgs/ingreditents/mustard-sauce.png";
import { Cheese, Patty, Sauce, Veggy } from "./CustomizeBurger";
export class BurgerBuilder {
  public burger!: string[];
  public ingredients!: string[];

  public ingredientsMapping = {
    [Patty.beef]: Beef,
    [Patty.chicken]: Chicken,
    [Patty.veg]: Veg,
    [Patty.pork]: Pork,
    [Sauce.mayo]: Mayo,
    [Sauce.mustard]: Mustard,
    [Sauce.tomatoKetcup]: TomatoKetcup,
    [Veggy.onion]: Onion,
    [Veggy.tomato]: Tomato,
    [Cheese.black]: Black,
    [Cheese.cheddar]: Cheddar,
  };
  basicBurger() {
    this.burger = [RegularTop, Cheddar, RegularBottom];
    this.ingredients = ["Cheese"];
    return this;
  }
  addIngredient(ingredient: Patty | Veggy | Sauce | Cheese) {
    this.burger.splice(1, 0, this.ingredientsMapping[ingredient]);
    this.ingredients.push(ingredient);
  }

  build() {
    return this.ingredients;
  }
}
