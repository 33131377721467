import { CheeseBurger } from "../../burgers/CheeseBurger";
import { ChickenBurger } from "../../burgers/ChickenBurger";
import { VegBurger } from "../../burgers/VegBurger";

export enum BurgerTypes {
  cheese = "cheese",
  chicken = "chicken",
  veg = "veg",
}

export function creator(type: BurgerTypes) {
  switch (type) {
    case BurgerTypes.cheese:
      return new CheeseBurger();
    case BurgerTypes.chicken:
      return new ChickenBurger();
    case BurgerTypes.veg:
      return new VegBurger();
  }
}
