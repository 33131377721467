import React from "react";
import Col from "antd/es/col";
const NavItem: React.FC<{ text: string; onClick: () => void }> = ({
  text,
  onClick,
}) => {
  return (
    <b className="text-white cursor-pointer hover:text-blue-400" onClick={onClick}>
      {text}
    </b>
  );
};

export default NavItem;
