import Row from "antd/es/row";
import Col from "antd/es/col";
import { useNavigate } from "react-router-dom";
import Layout from "antd/es/layout";
import Flex from "antd/es/flex";
import { Routes, Route } from "react-router-dom";
import Image from "antd/es/image";
import FactoryDetails from "./withPatterns/Factory/BurgerDetails";
import FactoryList from "./withPatterns/Factory/BurgersList";
import Logo from "./svgs/logo/Bite Me Burger-logos/Bite Me Burger-logos_transparent.png";
import NavItem from "./components/NavItem";
import BuildYourOwnBurger from "./withPatterns/Factory/builder/BuildYourOwnBurger";

function App() {
  const { Content, Header } = Layout;
  const navigate = useNavigate();
  return (
    <Flex>
      <Layout className="w-screen h-screen">
        <Header className="h-20">
          <Row align={"middle"} gutter={16} className=" h-full">
            <Col span={8}>
              <Image
                src={Logo}
                alt="Bite Me Burgers"
                height={80}
                width={"auto"}
              />
            </Col>
            <Col span={16} className=" h-full">
              <Row align={"middle"} gutter={64}>
                {[
                  { text: "Menu", link: "menu" },
                  { text: "Build Your Own Burger", link: "build" },
                ].map((type) => (
                  <Col>
                    <NavItem
                      text={type.text}
                      onClick={() => navigate(type.link)}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Header>
        <Content className="h-[calc(100vh-5rem)] overflow-y-scroll">
          <Row
            className="w-full h-full m-4"
            justify={"center"}
            align={"middle"}
          >
            <Col>
              <Routes>
                <Route path="*" element={<FactoryList />} />
                <Route path="menu" element={<FactoryList />} />
                <Route path="menu/:burgerName" element={<FactoryDetails />} />
                <Route path="build" element={<BuildYourOwnBurger />} />
              </Routes>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Flex>
  );
}

export default App;
