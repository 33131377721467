// import RegularTop from "../svgs/buns/dual-top.png";
// import RegularBottom from "../svgs/buns/dual-bottom.png";
// import Cheese from "../svgs/ingreditents/cheddar-cheese.png";
// import Beef from "../svgs/patties/beef.png";
// import Tomato from "../svgs/ingreditents/tomato.png";
// import Mayo from "../svgs/ingreditents/mayonaisse.png";

export enum Patty {
  "beef" = "beef",
  "chicken" = "chicken",
  "veg" = "veg",
  "pork" = "pork",
}

export enum Sauce {
  "mayo" = "mayo",
  "mustard" = "mustard",
  tomatoKetcup = "tomatoKetcup",
}

export enum Veggy {
  "onion" = "onion",
  "tomato" = "tomato",
}

export enum Cheese {
  black = "black",
  cheddar = "cheddar",
}
export class BurgerBuilder {
  //   public burger: string[] = [RegularTop, Cheese, RegularBottom];
  public ingredients: string[] = ["Cheese"];

  addIngredients(): string[];
  addIngredients(patty: Patty[]): string[];
  addIngredients(patty: Patty[], sauce: Sauce[]): string[];
  addIngredients(patty: Patty[], sauce: Sauce[], veggies: Veggy[]): string[];
  addIngredients(
    patty?: Patty[],
    sauce?: Sauce[],
    veggies?: Veggy[]
  ): string[] {
    if (!patty && !sauce && !veggies) return this.ingredients;
    if (!veggies && !sauce) return this.ingredients.concat(patty || []);
    if (!veggies)
      return this.ingredients.concat(patty || []).concat(sauce || []);
    return this.ingredients
      .concat(patty || [])
      .concat(sauce || [])
      .concat(veggies || []);
  }
}
