import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { useNavigate } from "react-router";
import { Burger } from "../../burgers/burger";
import { BurgerTypes, creator } from "./burgerStore";
import { enumToArray } from "../../utils/enumToArray";
import { Typography } from "antd";

const BurgerListCard: React.FC<{
  burger: Burger;
  onClick: () => void;
}> = ({ burger, onClick }) => {
  return (
    <Row
      justify={"center"}
      className="my-2 cursor-pointer hover:bg-blue-200"
      onClick={onClick}
    >
      <Col className="border-b border-b-gray-300 p-2" span={24}>
        <Row>
          <Col span={24}>
            <b>{burger.getName()}</b>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p className="text-gray-500">{burger.getDescription()}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const BurgersList = () => {
  const navigate = useNavigate();
  const { Title } = Typography;
  return (
    <Row className="w-full h-full overflow-y-scroll" justify={"center"}>
      <Col className="space-y-2 p-2" span={22}>
        <Row>
          <Col>
            <Title>Menu</Title>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col span={12}>
            {enumToArray(BurgerTypes).map((burgerType) => {
              /**
               * Creator method is called while mapping through all the burgers
               * from the enum
               * This code is indendent of burgers being added or removed,
               * provided each burger is implemented using Burger inteface
               */
              const burger = creator(burgerType as BurgerTypes);
              return (
                <BurgerListCard
                  burger={burger}
                  onClick={() =>
                    navigate(`/menu/${burger.getBurgerType()}`)
                  }
                />
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BurgersList;
